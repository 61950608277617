<template>
  <div>

    <app-step step="0" />

    <div class="container pt-4">
      <div class="row px-3">
        <div class="col-sm-3 border border-bottom-0">
          <div class="row text-primary d-flex justify-content-center">
            <div class="col-2 my-auto py-2"><h4 class="my-0"><i class="fad fa-box-open"></i></h4></div>
            <div class="col-8 my-auto py-2"><h5 class="my-0">Revisar orden</h5></div>
          </div>
        </div>
      </div>
    </div>

    <div class="container pb-5">

      <hr class="mt-0">

      <div class="row text-muted pt-2 h6 px-3">
        <div class="col-sm-6 d-none d-md-block">Producto</div>
        <div class="col-sm-2 d-none d-md-block text-right">Cantidad</div>
        <div class="col-sm-2 d-none d-md-block text-right">Subtotal</div>
        <div class="col-sm-2 d-none d-md-block text-right">Total</div>
      </div>

      <div v-for="(item, key) in order.list" :key="key"
        class="row text-muted my-2 px-3 pb-2">
        <div class="col-sm-6">({{ item.model }}) {{ item.description }}</div>
        <div class="col-sm-2 text-right">
          <div class="row d-flex justify-content-between">
            <span class="col-9 d-sm-block d-md-none">Cantidad: </span>
            <span class="col-md-12 col-3">{{ item.quantity }}</span>
          </div>
        </div>
        <div class="col-sm-2 text-right">
          <div class="row d-flex justify-content-between">
            <span class="col-9 d-sm-block d-md-none">Subtotal: </span>
            <span class="col-md-12 col-3">$ {{ item.subtotal }}</span>
          </div>
        </div>
        <div class="col-sm-2 text-right">
          <div class="row d-flex justify-content-between">
            <span class="col-9 d-sm-block d-md-none">Total: </span>
            <span class="col-sm-12 col-3">$ {{ item.total }}</span>
          </div>
        </div>
      </div>

      <hr class="mb-0">

      <div class="row px-3">
        <div class="col-6 col-sm-6 py-1"></div>
        <div class="col-3 col-sm-4 py-1 text-right border border-right-0 border-top-0"><strong class="text-primary">Subtotal</strong></div>
        <div class="col-3 col-sm-2 py-1 text-right border border-left-0 border-top-0"><strong class="text-muted">$ {{ subtotal }}</strong></div>
      </div>

      <div class="row px-3 pt-4">
        <div class="col-3 ml-auto px-0">
          <b-button to="/shipping-form" variant="primary" block>Siguiente</b-button>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: 'Order',
  data() {
    return {
      order: '',
      subtotal: '',
    }
  },
  components: {
    AppStep: () => import('@/components/AppStep.vue')
  },
  created() {
    document.title = "X-orsystem Pay"
    this.$store.commit('set_order', this.$cookies.get('store_order'))
    this.$store.commit('set_loading', { is_loading: true, redirect: '/order' })

    if(this.$store.getters.get_order == null || this.$store.getters.get_order == '') this.$router.push('/loading')
    else {
      this.order = JSON.parse(atob(this.$store.getters.get_order))
      this.$store.commit('set_order', this.order)

      this.subtotal = this.order.data.total
    }
  },
}
</script>